import styled from 'styled-components';

const TimelineStyled = styled.ul`
  margin: auto;
  padding: 50px 0;

  display: flex;
  flex-direction: column;
`;
export default TimelineStyled;
